import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useParams } from '../../../../modules/navigation'
import { useMove } from '../../../../modules/adminMoves'
import { useMoveState } from '../../../../modules/moves'
import Route from '../../../../app/Route.enum'
import { mergeClassName } from '../../../../utils/mergeClassName'
import ContainedPage from '../../../../components/layout/ContainedPage'
import Spinner from '../../../../components/Spinner'
import ServerError from '../../../../components/errors/ServerError'
import MobileHeader from '../../../../components/layout/MobileHeader/MobileHeader'
import PageTitle from '../../../../components/layout/PageTitle'
import PageContent from '../../../../components/layout/PageContent'
import Tabs, { Tab } from '../../../../components/Tabs'
import Header from './Header'
import DetailsTab from './details/DetailsTab'
import EstimateTab from './estimate/EstimateTab'
import QuotesTab from './quotes/QuoteTab'
import Sidebar from './sidebar/Sidebar'
import MobileTopSection from './sidebar/MobileTopSection'
import CustomizeQuotePage from '../CustomizeQuotePage'
import AccountingTab from './accounting/AccountingTab'
import NotesTab from './notes/NotesTab'

enum TabName {
  Customer = 'customer',
  Estimate = 'estimate',
  Quotes = 'quotes',
  Accounting = 'accounting',
  Notes = 'notes',
}

const MovePage: React.FC = () => {
  const { t } = useTranslation()
  const { moveId } = useParams()
  const { data: move, loading, error, refetch } = useMove(moveId, { reload: true })
  const [selectedTab, setSelectedTab] = useState(TabName.Customer)
  const {
    isMoveLocked,
    selectedQuote,
    hasCustomQuote,
    totalNotes,
  } = useMoveState(move)

  const handleRefreshMove = async () => {
    if (refetch) {
      await refetch()
    }
  }

  /**
   * if selected quote is removed while beeing selected, switch to customer tab
   */
  useEffect(() => {
    if (selectedTab === TabName.Estimate && !selectedQuote) {
      setSelectedTab(TabName.Customer)
    }
  }, [selectedQuote, selectedTab])

  /**
   * if a quote is customized while beeing on quotes tab, switch to estimate tab
   */
  useEffect(() => {
    if (selectedTab === TabName.Quotes && hasCustomQuote) {
      setSelectedTab(TabName.Estimate)
    }
  }, [hasCustomQuote, selectedTab])

  if (loading) {
    return (
      <ContainedPage>
        <Spinner floating />
      </ContainedPage>
    )
  }

  if (error) {
    return (
      <ContainedPage>
        <ServerError error={error} />
      </ContainedPage>
    )
  }

  return (
    <ContainedPage
      mobileHeader={(
        <MobileHeader
          backlinkTo={Route.AdminMoves}
          title={t('pages.admin.moves.title')}
        />
      )}
    >
      <PageTitle wide>
        <Header move={move} />
      </PageTitle>
      <PageContent wide>

        { isMoveLocked
          ? <CustomizeQuotePage move={move} />
          : (
            <div className="flex flex-col gap-x-4 2xl:flex-row 3xl:gap-x-8">
              <div className="2xl:hidden">
                <MobileTopSection move={move} />
              </div>

              <div className="grow">

                <div className="border-b border-zinc-200">
                  <Tabs
                    value={selectedTab}
                    onChange={(_, value) => { setSelectedTab(value) }}
                  >
                    <Tab
                      value={TabName.Customer}
                      label={t('pages.admin.move.tabs.details')}
                    />
                    { !hasCustomQuote && (
                      <Tab
                        value={TabName.Quotes}
                        label={t('pages.admin.move.tabs.quotes', { count: move.quotes?.length ?? 0 })}
                      />
                    ) }
                    <Tab
                      value={TabName.Estimate}
                      label={t('pages.admin.move.tabs.estimate')}
                      disabled={!selectedQuote}
                    />
                    <Tab
                      value={TabName.Accounting}
                      label={t('pages.admin.move.tabs.accounting')}
                    />
                    <Tab
                      value={TabName.Notes}
                      label={t('pages.admin.move.tabs.notes', { count: totalNotes })}
                    />
                  </Tabs>
                </div>

                <div className="py-4">
                  { selectedTab === TabName.Customer && (
                    <DetailsTab move={move} />
                  ) }

                  { selectedTab === TabName.Quotes && (
                    <QuotesTab move={move} />
                  ) }

                  { selectedTab === TabName.Estimate && (
                    <EstimateTab move={move} />
                  ) }

                  { selectedTab === TabName.Accounting && (
                    <AccountingTab
                      move={move}
                      refreshMove={handleRefreshMove}
                    />
                  ) }

                  { selectedTab === TabName.Notes && (
                    <NotesTab
                      move={move}
                    />
                  ) }

                </div>
              </div>

              <div className={mergeClassName(
                'hidden 2xl:block',
                selectedTab === TabName.Customer && 'block',
              )}
              >
                <Sidebar
                  move={move}
                  onEstimateClick={
                    selectedTab === TabName.Estimate
                      ? undefined
                      : () => { setSelectedTab(TabName.Estimate) }
                  }
                  onBalanceClick={
                    selectedTab === TabName.Accounting
                      ? undefined
                      : () => { setSelectedTab(TabName.Accounting) }
                  }
                />
              </div>
            </div>
            ) }

      </PageContent>
    </ContainedPage>
  )
}

export default MovePage
