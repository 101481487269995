import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles'
import Box from '@mui/material/Box'
import DownloadIcon from '@mui/icons-material/FileDownload'

import { useDataGridContext } from '../../DataGridContext'
import { formatNumber } from '../../../../utils/number'
import { getBulkActions } from '../../DataGrid.util'
import SearchBar from './SearchBar'
import DeletedRowToggleSwitch from './DeletedRowToggleSwitch'
import BulkActionsDropdown from './BulkActionsDropdown'
import { type CustomFiltersProps, type CustomFilters } from '../../DataGrid.type'
import ExportModal from '../../ExportModal'

const Container = styled('div')(({ theme }) => ({
  display: 'flex',
  paddingBottom: '1rem',
  gap: '1rem',
  [theme.breakpoints.down('lg')]: {
    flexDirection: 'column',
  },
}))

const LeftOptionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexGrow: 1,
  flexWrap: 'wrap',
  gap: '1rem',
}))

const RightOptionsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  gap: '1rem',
  [theme.breakpoints.down('md')]: {
    display: 'none',
  },
}))

type HeaderProps = {
  customFilters?: React.FC<CustomFiltersProps>
}

const Header: React.FC<HeaderProps> = ({ customFilters: CustomFiltersComponent }) => {
  const { t } = useTranslation()
  const {
    showToggleDeleted,
    count,
    actions,
    loading,
    query,
    setQuery,
    exportEnabled,
  } = useDataGridContext()
  const [exporting, setExporting] = useState(false)

  const bulkActions = getBulkActions(actions)
  const hasBulkActions = bulkActions.length > 0

  const { custom = {} } = query

  const onCustomFilterChange = (values: CustomFilters) => {
    setQuery({
      ...query,
      custom: values,
      page: 1,
    })
  }

  return (
    <Container>

      <ExportModal
        open={exporting}
        onClose={() => { setExporting(false) }}
      />

      <LeftOptionsContainer>
        <SearchBar />

        { CustomFiltersComponent && (
          <CustomFiltersComponent
            values={custom}
            setValues={onCustomFilterChange}
            disabled={loading}
          />
        ) }
      </LeftOptionsContainer>

      <RightOptionsContainer>
        { showToggleDeleted && <DeletedRowToggleSwitch /> }

        { count !== undefined && (
          <Box>
            { formatNumber(count) }
            { ' ' }
            { t('components.dataGrid.result', { count }) }
          </Box>
        ) }

        { hasBulkActions && <BulkActionsDropdown /> }
        { exportEnabled && (
          <Button
            variant="outlined"
            color="secondary"
            size="small"
            startIcon={<DownloadIcon />}
            onClick={() => { setExporting(true) }}
            disabled={count === 0}
          >
            { t('actions.export') }
          </Button>
        ) }
      </RightOptionsContainer>
    </Container>
  )
}

export default Header
