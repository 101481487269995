import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Permission, useCurrentUser, usePermission } from '../../../../modules/users'
import { type AdminUser, type UpdateUserAdminPayload } from '../../../../modules/adminUsers'
import {
  Form,
  Input,
  SingleCheckbox,
  PasswordField,
  SubmitButton,
  PhoneNumberField,
  RolesField,
} from '../../../form'
import { Row, Cell } from '../../../cell'
import Paper from '../../../Paper'
import InfoTooltip from '../../../InfoTooltip'
import Authentication from '../../../../pages/admin/user/ReadOnlyUser/Authentication'
import Privileges from '../../../../pages/admin/user/ReadOnlyUser/Privileges'
import useValidationSchema from './validationSchema'
import { formatFormOutput, userToFormData } from './formDataBuilder'

type EditUserFormProps = {
  user: AdminUser
  onSubmit: (payload: UpdateUserAdminPayload) => Promise<any>
}

const EditUserForm: React.FC<EditUserFormProps> = ({ user, onSubmit }) => {
  const { t } = useTranslation()
  const { data: currentUser } = useCurrentUser()
  const schema = useValidationSchema()
  const canEditUsersCredentials = usePermission(Permission.canEditUsersCredentials)
  const canPromoteUsers = usePermission(Permission.canPromoteUsers) && user.id !== currentUser?.id

  const form = useForm({
    resolver: yupResolver(schema),
    defaultValues: userToFormData(user, { canEditUsersCredentials, canPromoteUsers }),
  })

  const onFormSubmit = async (data: UpdateUserAdminPayload) => await onSubmit(formatFormOutput(data))

  return (
    <Form form={form} onSubmit={onFormSubmit}>
      <Paper>
        <Typography variant="h2">
          { t('pages.admin.user.personalInfo') }
        </Typography>

        <Row>
          <Cell>
            <Input
              name="firstName"
              label={t('forms.fields.firstName.label')}
              variant="standard"
            />
          </Cell>

          <Cell>
            <Input
              name="lastName"
              label={t('forms.fields.lastName.label')}
              variant="standard"
            />
          </Cell>
        </Row>

        <Row>
          <Cell>
            <div className="mt-[9px]">
              <PhoneNumberField
                name="phoneNumber"
                label={t('forms.fields.phoneNumber.label')}
                variant="standard"
              />
            </div>
          </Cell>

          <Cell>
            <Input
              name="position"
              label={t('forms.fields.position.label')}
              variant="standard"
            />
          </Cell>
        </Row>

        <Row>
          <Cell>
            <Input
              name="email"
              label={t('forms.fields.email.label')}
              variant="standard"
            /><br />
            <SingleCheckbox
              name="emailVerified"
              label={t('forms.fields.emailVerified.label')}
            />
          </Cell>

          <Cell>
            <Input
              name="photo"
              label={t('forms.fields.photoUrl.label')}
              variant="standard"
            />
          </Cell>
        </Row>
      </Paper>

      <Paper>
        <Typography variant="h2" mb={1}>
          { t('pages.admin.user.authentication') }
        </Typography>
        <Row>
          <Cell>
            { canEditUsersCredentials
              ? (
                <>
                  <PasswordField
                    name="password"
                    label={t('forms.fields.newPassword.label')}
                    variant="standard"
                    showValidations
                  />
                  <SingleCheckbox
                    name="mfaEnabled"
                    label={(
                      <Box display="flex" alignItems="center" gap="0.25rem">
                        { t('forms.fields.mfaEnabled.label') }
                        { user.mfaEnabled
                          ? <InfoTooltip warning message={t('forms.fields.mfaEnabled.warningBeforeDisable')} />
                          : <InfoTooltip message={t('forms.fields.mfaEnabled.infoCannotEnable')} /> }
                      </Box>
                    )}
                    disabled={!user.mfaEnabled}
                  />
                </>
                )
              : <Authentication user={user} /> }
          </Cell>
          <Cell />
        </Row>
      </Paper>

      <Paper>
        <Typography variant="h2" mb={1}>
          { t('pages.admin.user.privileges') }
        </Typography>

        <Row>
          <Cell>
            { canPromoteUsers
              ? <RolesField name="roles" user={user} />
              : <Privileges user={user} /> }
          </Cell>
        </Row>
      </Paper>

      <Box mt="2rem">
        <SubmitButton>
          { t('actions.save') }
        </SubmitButton>
      </Box>
    </Form>
  )
}

export default EditUserForm
