import { useTranslation } from 'react-i18next'
import {
  useForm,
} from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import InputAdornment from '@mui/material/InputAdornment'
import Alert from '@mui/material/Alert'

import {
  type CloseJobForPartnerPayload,
  type JobForPartner,
} from '../../../../modules/jobs'
import { useMoveState } from '../../../../modules/moves'
import { useCurrentCompanyBranch } from '../../../../modules/companies'
import {
  Form,
  Input,
  SingleCheckbox,
  SubmitButton,
} from '../../../form'
import {
  jobToFormData,
  formatFormOutput,
} from './formDataBuilder'
import useValidationSchema from './validationSchema'
import { type CloseJobFormValues } from './CloseJobForm.types'
import CloseJobRow from './CloseJobRow'
import InfoTooltip from '../../../InfoTooltip'

type CloseJobFormProps = {
  job: JobForPartner
  onSubmit: (values: CloseJobForPartnerPayload) => void | Promise<void>
}

const CloseJobForm: React.FC<CloseJobFormProps> = ({
  job,
  onSubmit,
}) => {
  const { t } = useTranslation()
  const schema = useValidationSchema()
  const defaultValues = jobToFormData(job)
  const { isUsingDoubleDriveTime } = useMoveState().forMove(job.move)
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()

  const movingLabour = parseFloat(defaultValues.movingLabour ?? '0')
  const travelTime = parseFloat(defaultValues.travelTime ?? '0')
  const travelDistance = parseFloat(defaultValues.travelDistance ?? '0')

  const hasMovingLabour = !!movingLabour
  const hasTransportLabour = !!defaultValues.transportLabour
  const hasPackingLabour = !!defaultValues.packingLabour

  const hasTravelTime = !!travelTime
  const hasTravelDistance = !!travelDistance
  const hasTransportDistance = !!defaultValues.transportDistance

  const hasStairs = !!defaultValues.nbStairs

  const hasMovingLabourCategory = hasMovingLabour || hasTransportLabour || hasPackingLabour
  const hasTripAndTravelCategory = hasTravelTime || hasTravelDistance || hasTransportDistance

  const minLabourTime = currentCompanyBranch?.labour?.minLabourTime ?? 0
  const showMinLabourTime = minLabourTime > 0 && minLabourTime <= movingLabour

  const travelRule = currentCompanyBranch
    ?.tripAndTravel
    ?.rules
    ?.find(rule => rule.to >= travelDistance)

  const minTravelTime = travelRule?.travel.chargeLabour ? travelRule.travel.minLabourTime ?? 0 : 0
  const showMinTravelTime = minTravelTime > 0 && minTravelTime <= travelTime

  const form = useForm<CloseJobFormValues>({
    resolver: yupResolver(schema),
    defaultValues,
  })

  const handleSubmit = async (values: CloseJobFormValues) => {
    await onSubmit(formatFormOutput(values))
  }

  return (
    <Form
      form={form}
      onSubmit={handleSubmit}
    >
      <Alert
        severity="info"
        className="mb-6"
      >
        { t('forms.closeJob.topInfo.title') }
        { ' ' }
        <strong>
          { t('forms.closeJob.topInfo.enterActualValues') }
        </strong>
        <br />
        { t('forms.closeJob.topInfo.contactUsIfNeeded') }
      </Alert>

      { hasMovingLabourCategory && (
        <>
          <h2 className="my-4 font-sans text-2xl">
            { t('forms.closeJob.movingLabour.title') }
          </h2>

          <div className="flex flex-col gap-y-2">
            { hasMovingLabour && (
              <CloseJobRow
                label={t('forms.closeJob.movingLabour.movingLabour.label')}
                description={(
                  <div>
                    { t('forms.closeJob.movingLabour.movingLabour.description') }
                    { showMinLabourTime && (
                      <div className="ml-2 inline-flex items-center gap-x-2 font-bold">
                        ({ t('forms.closeJob.minTime', { count: minLabourTime }) })
                        <InfoTooltip message={t('forms.closeJob.minTimeInfo')} />
                      </div>
                    ) }
                  </div>
                )}
              >
                <Input
                  name="movingLabour"
                  size="small"
                  className="md:max-w-[200px]"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        { t('units.hour_s') }
                      </InputAdornment>
                    ),
                  }}
                />
              </CloseJobRow>
            ) }

            { hasTransportLabour && (
              <CloseJobRow
                label={t('forms.closeJob.movingLabour.transportLabour.label')}
                description={(
                  <>
                    { t('forms.closeJob.movingLabour.transportLabour.description') }
                    { isUsingDoubleDriveTime && (
                      <div className="text-neutral-500">
                        <strong>
                          { t('forms.closeJob.movingLabour.transportLabour.doubleDriveTime.california') }
                          :
                        </strong>
                        { ' ' }
                        { t('forms.closeJob.movingLabour.transportLabour.doubleDriveTime.description') }
                      </div>
                    ) }
                  </>
                )}
              >
                <Input
                  name="transportLabour"
                  size="small"
                  className="md:max-w-[200px]"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        { t('units.hour_s') }
                      </InputAdornment>
                    ),
                  }}
                />
              </CloseJobRow>
            ) }

            { hasPackingLabour && (
              <CloseJobRow
                label={t('forms.closeJob.movingLabour.packingLabour.label')}
                description={t('forms.closeJob.movingLabour.packingLabour.description')}
              >
                <Input
                  name="packingLabour"
                  size="small"
                  className="md:max-w-[200px]"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        { t('units.hour_s') }
                      </InputAdornment>
                    ),
                  }}
                />
              </CloseJobRow>
            ) }
          </div>
        </>
      ) }

      { hasTripAndTravelCategory && (
        <>
          <h2 className="my-4 font-sans text-2xl">
            { t('forms.closeJob.tripAndTravel.title') }
          </h2>

          <div className="flex flex-col gap-y-2">
            { hasTravelTime && (
              <CloseJobRow
                label={t('forms.closeJob.tripAndTravel.travelTime.label')}
                description={(
                  <div>
                    { t('forms.closeJob.tripAndTravel.travelTime.description') }
                    { showMinTravelTime && (
                      <div className="ml-2 inline-flex items-center gap-x-2 font-bold">
                        ({ t('forms.closeJob.minTime', { count: minTravelTime }) })
                        <InfoTooltip message={t('forms.closeJob.minTimeInfo')} />
                      </div>
                    ) }
                  </div>
                )}
              >
                <Input
                  name="travelTime"
                  size="small"
                  className="md:max-w-[200px]"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        { t('units.hour_s') }
                      </InputAdornment>
                    ),
                  }}
                />
              </CloseJobRow>
            ) }

            { hasTravelDistance && (
              <CloseJobRow
                label={t('forms.closeJob.tripAndTravel.travelDistance.label')}
                description={t('forms.closeJob.tripAndTravel.travelDistance.description')}
              >
                <Input
                  name="travelDistance"
                  size="small"
                  className="md:max-w-[200px]"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        { t('units.km') }
                      </InputAdornment>
                    ),
                  }}
                />
              </CloseJobRow>
            ) }

            { hasTransportDistance && (
              <CloseJobRow
                label={t('forms.closeJob.tripAndTravel.transportDistance.label')}
                description={t('forms.closeJob.tripAndTravel.transportDistance.description')}
              >
                <Input
                  name="transportDistance"
                  size="small"
                  className="md:max-w-[200px]"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        { t('units.km') }
                      </InputAdornment>
                    ),
                  }}
                />
              </CloseJobRow>
            ) }
          </div>
        </>
      ) }

      { hasStairs && (
        <>
          <h2 className="my-4 font-sans text-2xl">
            { t('forms.closeJob.additionalCharges.title') }
          </h2>

          <div className="flex flex-col gap-y-2">
            <CloseJobRow
              label={t('forms.closeJob.additionalCharges.stairs.label')}
              description={t('forms.closeJob.additionalCharges.stairs.description')}
            >
              <Input
                name="nbStairs"
                size="small"
                className="md:max-w-[200px]"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      { t('units.stairs') }
                    </InputAdornment>
                  ),
                }}
              />
            </CloseJobRow>
          </div>
        </>
      ) }

      <div className="my-8 rounded-md border bg-orange-50 px-4 py-8 md:px-8">
        <SingleCheckbox
          name="confirmation"
          label={(
            <div className="">
              { t('forms.closeJob.confirmation.title') }
              <ul className="mt-4 list-disc text-neutral-600 [&_>li]:ml-4 [&_>li]:md:ml-8">
                <li>
                  { t('forms.closeJob.confirmation.actualValues') }
                </li>
                <li>
                  { t('forms.closeJob.confirmation.chargesDiscussedWithCustomer') }
                </li>
                <li>
                  { t('forms.closeJob.confirmation.customerWillBeCharged') }
                </li>
              </ul>
            </div>
          )}
        />
      </div>

      <SubmitButton>
        { t('actions.closeJob') }
      </SubmitButton>

    </Form>
  )
}

export default CloseJobForm
