import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { addDays } from 'date-fns/addDays'
import { startOfDay } from 'date-fns/startOfDay'
import { endOfDay } from 'date-fns/endOfDay'
import { subMonths } from 'date-fns/subMonths'

import { type QueryResult } from '../../graphQl'
import * as queries from './jobs.queries'
import * as mutations from './jobs.mutations'
import { useCurrentCompanyBranch } from '../../companies'
import { type CloseJobForPartnerPayload, JobStatus, type JobForPartner, type JobsQuery, type LightJob, type UpdateNotePayload, type UploadNoteAttachmentPayload, type DeleteNoteAttachmentPayload } from './jobs.type'
import { sortJobs } from './jobs.utils'

type FetchJobsOptions = {
  reload?: boolean
  companyBranchId?: string
}

type UseJobsHookResult = {
  fetchJobs: (variables: JobsQuery, options?: FetchJobsOptions) => Promise<LightJob[]>
} & QueryResult<LightJob[]>

/**
 * returns all jobs for a given company branch
 */
export const useJobs = ({ reload = false, ...queryArgs } = {}): UseJobsHookResult => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const [fetch, { data, networkStatus, loading, error, ...queryProps }] = useLazyQuery(
    queries.getJobs,
    {
      fetchPolicy,
      ...queryArgs,
    },
  )
  const fetchJobs = async (query: JobsQuery = {}, {
    reload = false,
    companyBranchId,
  }: FetchJobsOptions = {}) => {
    const fetchPolicy = reload ? 'network-only' : 'cache-first'
    const { data } = await fetch({
      variables: {
        companyBranchId: companyBranchId ?? currentCompanyBranch?.id,
        ...query,
      },
      fetchPolicy,
    })
    return data?.getJobs
  }

  return {
    fetchJobs,
    data: data?.getJobs,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * returns all upcoming jobs (7 days) for a given company branch
 */
export const useUpcomingJobs = ({ reload = false, ...queryArgs } = {}): QueryResult<LightJob[]> => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const today = startOfDay(new Date())

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getJobs,
    {
      skip: !currentCompanyBranch?.approved,
      variables: {
        companyBranchId: currentCompanyBranch?.id,
        status: [
          JobStatus.Pending,
          JobStatus.Completed,
        ],
        dateRangeFilter: {
          from: today,
          to: addDays(today, 7),
        },
      },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.getJobs ? sortJobs(data.getJobs, 'ASC') : [],
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * returns all upcoming jobs (7 days) for a given company branch
 */
export const useJobsReadyToBeClosed = ({ reload = false, ...queryArgs } = {}): QueryResult<LightJob[]> => {
  const { data: currentCompanyBranch } = useCurrentCompanyBranch()
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const today = endOfDay(new Date())

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getJobs,
    {
      skip: !currentCompanyBranch?.approved,
      variables: {
        companyBranchId: currentCompanyBranch?.id,
        status: [
          JobStatus.Completed,
        ],
        dateRangeFilter: {
          from: subMonths(today, 3),
          to: today,
        },
      },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.getJobs ? sortJobs(data.getJobs, 'ASC') : [],
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * get a job by id
 */
export const useJob = (jobId?: string, { reload = false, ...queryArgs } = {}): QueryResult<JobForPartner | undefined> => {
  const fetchPolicy = reload ? 'network-only' : 'cache-first'

  const { data, networkStatus, loading, error, ...queryProps } = useQuery(
    queries.getJob,
    {
      variables: { jobId },
      fetchPolicy,
      ...queryArgs,
    },
  )

  return {
    data: data?.getJob,
    loading: (loading && networkStatus === 1),
    error,
    ...queryProps,
  }
}

/**
 * close a job (for partners)
 */
export const useCloseJobForPartnerAction = () => {
  const [closeJobForPartner, { data, loading, ...mutationProps }] = useMutation(mutations.closeJobForPartner)

  return {
    closeJobForPartner: async (jobId: string, payload: CloseJobForPartnerPayload) => {
      const { data } = await closeJobForPartner({
        variables: {
          jobId,
          payload,
        },
      })

      return data.closeJobForPartner
    },
    data: data?.closeJobForPartner,
    loading,
    ...mutationProps,
  }
}

/**
 * update a note for a job
 */
export const useUpdateJobNoteAction = () => {
  const [updateJobNote, { data, loading, ...mutationProps }] = useMutation(mutations.updateJobNote)

  return {
    updateJobNote: async (jobId: string, payload: UpdateNotePayload) => {
      const { data } = await updateJobNote({
        variables: {
          jobId,
          payload,
        },
      })

      return data.updateJobNote
    },
    data: data?.updateJobNote,
    loading,
    ...mutationProps,
  }
}

/**
 * upload a note attachment for a job
 */
export const useUploadJobNoteAttachmentAction = () => {
  const [uploadJobNoteAttachment, { data, loading, ...mutationProps }] = useMutation(mutations.uploadJobNoteAttachment)

  return {
    uploadJobNoteAttachment: async (jobId: string, payload: UploadNoteAttachmentPayload) => {
      const { data } = await uploadJobNoteAttachment({
        variables: {
          jobId,
          payload,
        },
      })

      return data.uploadJobNoteAttachment
    },
    data: data?.uploadJobNoteAttachment,
    loading,
    ...mutationProps,
  }
}

/**
 * delete a note attachment for a move
 */
export const useDeleteJobNoteAttachmentAction = () => {
  const [deleteJobNoteAttachment, { data, loading, ...mutationProps }] = useMutation(mutations.deleteJobNoteAttachment)

  return {
    deleteJobNoteAttachment: async (jobId: string, payload: DeleteNoteAttachmentPayload) => {
      const { data } = await deleteJobNoteAttachment({
        variables: {
          jobId,
          payload,
        },
      })

      return data.deleteJobNoteAttachment
    },
    data: data?.deleteJobNoteAttachment,
    loading,
    ...mutationProps,
  }
}
