import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { isToday } from 'date-fns/isToday'
import { isPast } from 'date-fns/isPast'
import { differenceInDays } from 'date-fns/differenceInDays'
import Chip from '@mui/material/Chip'
import Button from '@mui/material/Button'
import AddIcon from '@mui/icons-material/AddCircleOutline'
import ArrowIcon from '@mui/icons-material/ArrowForward'
import UserIcon from '@mui/icons-material/Person'
import LockIcon from '@mui/icons-material/LockPersonOutlined'
import PendingDepositIcon from '@mui/icons-material/CreditCardOffOutlined'

import { SortDirection } from '../../../modules/graphQl'
import {
  type BasicUser,
  Permission,
  protectAdminPage,
  usePermission,
} from '../../../modules/users'
import {
  DepositStatus,
  useMoves,
} from '../../../modules/adminMoves'
import { useLightMoveState } from '../../../modules/moves'
import { useNavigate } from '../../../modules/navigation'
import Route from '../../../app/Route.enum'
import { useDateFormatter } from '../../../utils/date'
import { capitalizeFirstLetter } from '../../../utils/string'
import { mergeClassName } from '../../../utils/mergeClassName'
import ContainedPage from '../../../components/layout/ContainedPage/ContainedPage'
import PageContent from '../../../components/layout/PageContent/PageContent'
import PageTitle from '../../../components/layout/PageTitle/PageTitle'
import {
  DataGrid,
  Column,
  Row,
  Cell,
  SmallValue,
  DateValue,
  BooleanValue,
  type GridQuery,
} from '../../../components/dataGrid'
import ServerError from '../../../components/errors/ServerError'
import Price from '../../../components/Price'
import TimeAgo from '../../../components/TimeAgo'
import CustomFilters, {
  PAST_MOVES_FILTER,
  CUSTOMERS_FILTER,
  ASSIGNED_FILTER,
  DATE_RANGE_FILTER,
} from './CustomFilters'
import Link, { LinkVariant } from '../../../components/Link'
import Tooltip from '../../../components/Tooltip'
import { useExportFormatter } from './useExportFormatter'

const MovesPage: React.FC = () => {
  const { t } = useTranslation()
  const { fetchMoves, data: moves, error: movesErrors } = useMoves({ reload: true })
  const { fetchMoves: exportMoves } = useMoves({ reload: true })
  const exportFormatter = useExportFormatter()
  const getMoveState = useLightMoveState().forMove
  const formatDate = useDateFormatter()
  const { navigate } = useNavigate()
  const canManageMoves = usePermission(Permission.canManageMoves)
  const canReadUsers = usePermission(Permission.canReadUsers)
  const canExportCustomers = usePermission(Permission.canExportCustomers)

  const formatQuery = ({ custom, ...query }: GridQuery) => {
    const showPastMoves = custom?.[PAST_MOVES_FILTER]
    const customersFilter = custom?.[CUSTOMERS_FILTER]
    const assignedFilter = custom?.[ASSIGNED_FILTER]
    const dateRangeFilter = custom?.[DATE_RANGE_FILTER]
    return {
      query,
      showPastMoves,
      customersFilter,
      assignedFilter,
      dateRangeFilter,
    }
  }

  const onQueryUpdate = useCallback(async (gridQuery: GridQuery) => {
    return await fetchMoves(formatQuery(gridQuery))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onExport = useCallback(async (gridQuery: GridQuery) => {
    return await exportMoves(formatQuery(gridQuery))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const renderUser = (user?: BasicUser) => {
    if (!user) {
      return null
    }
    const identification = (
      <>
        <UserIcon className="mr-2 !text-[18px] text-slate-500" />

        { capitalizeFirstLetter(user.firstName) }
        { ' ' }
        { capitalizeFirstLetter(user.lastName) }
      </>
    )
    return (
      canReadUsers
        ? (
          <Link
            to={Route.AdminUser}
            params={{ userId: user.id }}
            variant={LinkVariant.Primary}
            onClick={event => { event.stopPropagation() }}
          >
            { identification }
          </Link>
          )
        : identification
    )
  }

  if (movesErrors) {
    return <ContainedPage><ServerError error={movesErrors} /></ContainedPage>
  }

  const handleRowClick = (id: string, newTab = false) => {
    navigate(Route.AdminMove, { moveId: id }, newTab)
  }

  const renderMovingDateStatus = (date: Date) => {
    if (isToday(date)) {
      return (
        <Chip
          className="!bg-sky-200/60 !text-xs"
          label={t('time.today')}
          size="small"
        />
      )
    }
    if (isPast(date)) {
      return (
        <Chip
          className="!text-xs !lowercase"
          label={<TimeAgo date={date} />}
          size="small"
        />
      )
    }
    const daysBeForeMove = differenceInDays(date, new Date()) + 1
    if (daysBeForeMove >= 31) {
      const monthsBeforeMove = Math.ceil(daysBeForeMove / 31)
      return (
        <Chip
          className="!bg-sky-200/60 !text-xs"
          label={t('time.inMonths', { count: monthsBeforeMove })}
          size="small"
        />
      )
    }
    return (
      <Chip
        className="!bg-sky-200/60 !text-xs"
        label={t('time.inDays', { count: daysBeForeMove })}
        size="small"
      />
    )
  }

  return (
    <ContainedPage>
      <PageTitle fullWidth>
        <div className="flex flex-col justify-between gap-4 lg:flex-row">

          { t('pages.admin.moves.title') }

          { canManageMoves && (
            <Button
              variant="contained"
              color="secondary"
              startIcon={<AddIcon />}
              onClick={() => { navigate(Route.AdminCreateMove) }}
            >
              { t('actions.create') }
            </Button>
          ) }

        </div>
      </PageTitle>

      <PageContent fullWidth className="py-8">
        <DataGrid
          name="moves"
          count={moves?.count ?? 0}
          onQueryUpdate={onQueryUpdate}
          initialQuery={{ sortField: 'createdAt', sortDirection: SortDirection.DESC }}
          customFilters={CustomFilters}
          saveCustomFilterValues={[PAST_MOVES_FILTER, CUSTOMERS_FILTER, ASSIGNED_FILTER, DATE_RANGE_FILTER]}
          exportEnabled={canExportCustomers}
          exportCallback={onExport}
          exportFormatter={exportFormatter}
          columns={(
            <>
              <Column name="createdAt" sortable>
                { t('pages.admin.moves.table.columns.createdAt') }
              </Column>

              <Column name="movingDate" sortable>
                { t('pages.admin.moves.table.columns.movingDate') }
              </Column>

              <Column name="user.firstName" sortable>
                { t('pages.admin.moves.table.columns.customer') }
              </Column>

              <Column name="agent">
                { t('pages.admin.moves.table.columns.agent') }
              </Column>

              <Column name="nbQuotes">
                { t('pages.admin.moves.table.columns.nbQuotes') }
              </Column>

              <Column name="nbMen" defaultVisible={false}>
                { t('pages.admin.moves.table.columns.nbMen') }
              </Column>

              <Column name="nbTrucks" defaultVisible={false}>
                { t('pages.admin.moves.table.columns.nbTrucks') }
              </Column>

              <Column name="quoteSelected.companyName">
                { t('pages.admin.moves.table.columns.companySelected') }
              </Column>

              <Column name="quoteSelected.subtotal.price">
                { t('pages.admin.moves.table.columns.quotePrice') }
              </Column>

              <Column name="activePromoCode.code" defaultVisible={false}>
                { t('pages.admin.moves.table.columns.promoCode') }
              </Column>

              <Column name="quoteAccepted.date" sortable>
                { t('pages.admin.moves.table.columns.accepted') }
              </Column>

              <Column name="moveApprovedByAgent" sortable>
                { t('pages.admin.moves.table.columns.moveApprovedByAgent') }
              </Column>

              <Column name="depositStatus">
                { t('pages.admin.moves.table.columns.depositStatus') }
              </Column>

              <Column name="origin.address.city" sortable>
                { t('pages.admin.moves.table.columns.move') }
              </Column>

              <Column name="platform" sortable defaultVisible={false}>
                { t('pages.admin.moves.table.columns.platform') }
              </Column>

              <Column name="utmSource" sortable defaultVisible={false}>
                { t('pages.admin.moves.table.columns.utmSource') }
              </Column>

              <Column name="utmMedium" sortable defaultVisible={false}>
                { t('pages.admin.moves.table.columns.utmMedium') }
              </Column>

              <Column name="utmCampaign" sortable defaultVisible={false}>
                { t('pages.admin.moves.table.columns.utmCampaign') }
              </Column>
            </>
          )}
        >
          { moves?.data?.map(move => {
            const {
              isMoveLocked,
              moveDate,
            } = getMoveState(move)

            return (
              <Row
                key={move.id}
                id={move.id}
                onClick={handleRowClick}
                warning={isMoveLocked}
              >
                <Cell>
                  <SmallValue>
                    <DateValue date={move.createdAt} />
                  </SmallValue>
                </Cell>

                <Cell>
                  <div className="flex gap-2">
                    <div className="min-w-[90px]">
                      <SmallValue>
                        { formatDate(
                          moveDate,
                          {
                            showYear: false,
                          },
                        ) }
                      </SmallValue>
                    </div>
                    { renderMovingDateStatus(new Date(move.movingDate)) }
                  </div>
                </Cell>

                <Cell expand>
                  <div className="flex items-center">
                    { move.locked && (
                      <Tooltip tooltip={t('pages.admin.moves.table.lockedMove')}>
                        <LockIcon className="mr-1 !text-[22px] text-orange-800" />
                      </Tooltip>
                    ) }
                    { capitalizeFirstLetter(move.user.firstName) }
                    { ' ' }
                    { capitalizeFirstLetter(move.user.lastName) }
                  </div>
                </Cell>

                <Cell>
                  { renderUser(move.agent) }
                </Cell>

                <Cell>
                  { move.nbQuotes > 0
                    ? (
                      <Chip
                        className="!bg-green-700 !text-white"
                        label={t('pages.admin.moves.table.quotes', { count: move.nbQuotes })}
                        size="small"
                      />
                      )
                    : (
                      <Chip
                        className="!text-xs"
                        label={t('pages.admin.moves.table.agentRequired')}
                        size="small"
                      />
                      ) }
                </Cell>

                <Cell center>
                  { move.nbMen }
                </Cell>

                <Cell center>
                  { move.nbTrucks }
                </Cell>

                <Cell>
                  <SmallValue>
                    { capitalizeFirstLetter(move.quoteSelected?.companyName) }
                  </SmallValue>
                </Cell>

                <Cell right>
                  <div className="pr-8">
                    { move.quoteSelected?.subtotal && (
                      <Price amount={move.quoteSelected.subtotal} compact />
                    ) }
                  </div>
                </Cell>

                <Cell center>
                  {
                  move.activePromoCode
                    ? <SmallValue>{ move.activePromoCode.code }</SmallValue>
                    : <BooleanValue value={false} />
                  }
                </Cell>

                <Cell center>
                  <div className="flex items-center">
                    { move.quoteAccepted && move.depositStatus === DepositStatus.pending
                      ? (
                        <Tooltip tooltip={t('pages.admin.moves.table.acceptedNotPaid')}>
                          <PendingDepositIcon className="!pr-1 !text-[20px] text-orange-700" />
                        </Tooltip>
                        )
                      : (
                        <BooleanValue value={!!move.quoteAccepted} />
                        ) }
                    { ' ' }
                    { move.quoteAccepted && (
                      <SmallValue>
                        ({ formatDate(move.quoteAccepted.date, { showYear: false, shortMonth: true }) })
                      </SmallValue>
                    ) }
                  </div>
                </Cell>

                <Cell center>
                  <BooleanValue value={move.moveApprovedByAgent} />
                </Cell>

                <Cell>
                  { move.depositStatus && (
                    <Chip
                      className={mergeClassName(
                        move.depositStatus === DepositStatus.paid && '!bg-green-700 !text-white',
                      )}
                      label={t(`status.${move.depositStatus}`)}
                      size="small"
                    />
                  ) }
                </Cell>

                <Cell>
                  <div className="flex items-center gap-2">
                    <SmallValue>
                      { capitalizeFirstLetter(move.origin.address.city) }
                      { ', ' }
                      { move.origin.address.region?.toUpperCase() }
                    </SmallValue>

                    <ArrowIcon className="!text-[20px] text-neutral-600" />

                    <SmallValue>
                      { capitalizeFirstLetter(move.destination.address.city) }
                      { ', ' }
                      { move.destination.address.region?.toUpperCase() }
                    </SmallValue>
                  </div>
                </Cell>

                <Cell>
                  { capitalizeFirstLetter(move.platform) }
                </Cell>

                <Cell>
                  { move.utmSource }
                </Cell>

                <Cell>
                  { move.utmMedium }
                </Cell>

                <Cell>
                  { move.utmCampaign }
                </Cell>
              </Row>
            )
          }) }
        </DataGrid>
      </PageContent>
    </ContainedPage>
  )
}

export default protectAdminPage(MovesPage)
