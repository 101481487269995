import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm } from 'react-hook-form'
import Button from '@mui/material/Button'
import NoteIcon from '@mui/icons-material/DescriptionOutlined'

import {
  NoteScope,
  useDeleteNoteAttachmentAction,
  useMove,
  useUploadNoteAttachmentAction,
} from '../../../../modules/adminMoves'
import { useMoveState } from '../../../../modules/moves'
import {
  Form,
  NoteField,
  SingleCheckbox,
  SubmitButton,
} from '../../../form'
import { ModalDescription, ModalFooter } from '../../../Modal'
import { type ApproveMoveFormValues } from './ApproveMoveConfirmationForm.types'
import { useErrorFormatter } from '../../../errors/useErrorFormatter'
import { errorNotification } from '../../../ToastNotifications'

type ApproveMoveConfirmationFormProps = {
  moveId: string
  onConfirm: (payload: ApproveMoveFormValues) => void
  onDecline: () => void
}

const ApproveMoveConfirmationForm: React.FC<ApproveMoveConfirmationFormProps> = ({
  moveId,
  onConfirm,
  onDecline,
}) => {
  const { data: move } = useMove(moveId)
  const { t } = useTranslation()
  const form = useForm({
    defaultValues: {
      note: move?.notes?.toPartner?.note,
      shareNoteFromCustomer: move?.notes?.shareNoteFromCustomer ?? undefined,
    },
  })
  const [uploading, setUploading] = useState(false)
  const { hasNoteFromCustomer } = useMoveState(move)
  const { uploadMoveNoteAttachment } = useUploadNoteAttachmentAction()
  const { deleteMoveNoteAttachment } = useDeleteNoteAttachmentAction()
  const formatError = useErrorFormatter()

  if (!move) {
    return null
  }

  const handleUpload = async (file: File) => {
    try {
      await uploadMoveNoteAttachment(move.id, { file, scope: NoteScope.toPartner })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleDeleteFile = async (fileId: string) => {
    try {
      await deleteMoveNoteAttachment(move.id, { fileId, scope: NoteScope.toPartner })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const onSubmit = async (values: ApproveMoveFormValues) => {
    onConfirm(values)
  }

  return (
    <Form form={form} onSubmit={onSubmit}>
      <ModalDescription>
        <div className="mb-4 text-base">
          <div className="mb-4">
            { t('pages.admin.move.actions.approveMove.confirmation.question') }
          </div>

          <div className="mb-2 mt-4 flex items-center gap-x-2 text-base font-bold text-neutral-500">
            <NoteIcon className="!text-[16px]" />
            { t('forms.fields.shareNoteWithPartner.label') }
          </div>

          <div className="rounded-md border p-2">
            <NoteField
              name="note"
              files={move.notes?.toPartner?.attachments}
              onUpload={handleUpload}
              onDeleteFile={handleDeleteFile}
              onUploading={setUploading}
              className="border-none [&_.field-wrapper]:p-[2px]"
              autoFocus
            />
          </div>

          { hasNoteFromCustomer && (
            <div className="mt-2 rounded-md border bg-white pl-2">
              <SingleCheckbox
                name="shareNoteFromCustomer"
                label={(
                  <span className="text-sm">
                    { t('forms.fields.shareNoteFromCustomer.label') }
                  </span>
                )}
              />
            </div>
          ) }
        </div>
      </ModalDescription>
      <ModalFooter>
        <div>
          <Button
            variant="outlined"
            color="secondary"
            onClick={onDecline}
            size="medium"
          >
            { t('actions.cancel') }
          </Button>
        </div>
        <div>
          <SubmitButton
            variant="contained"
            size="medium"
            disabled={uploading}
          >
            { t('actions.confirm') }
          </SubmitButton>
        </div>
      </ModalFooter>
    </Form>
  )
}

export default ApproveMoveConfirmationForm
