import { useState, useEffect } from 'react'
import { json2csv } from 'json-2-csv'
import { Buffer } from 'buffer'

import { downloadBase64Data } from '../../../utils/file'
import { useDataGridContext } from '../DataGridContext'
import { useVisibleColumns } from '../useToggleColumns'

const nbResultsPerPage = 100
const maxResults = 2000
const cooldownMs = 1000

type UseExportOptions = {
  enabled: boolean
}

export const useExport = ({
  enabled,
}: UseExportOptions) => {
  const {
    count = 0,
    query,
    columns,
    exportCallback,
    exportFormatter,
  } = useDataGridContext()
  const visibleColumns = useVisibleColumns()
  const [data, setData] = useState<unknown[]>([])
  const [currentPage, setCurrentPage] = useState<number>()
  const [totalPages, setTotalPages] = useState<number>()
  const [completed, setCompleted] = useState(false)

  const rowsToDownload = Math.min(count, maxResults)
  const progress = completed
    ? 100
    : Math.floor(((((currentPage ?? 1) - 1) * nbResultsPerPage) / rowsToDownload) * 100)

  const wait = async (delay: number) => await new Promise(resolve => setTimeout(resolve, delay))

  /**
   * reset current page when we start exporting
   */
  useEffect(() => {
    if (enabled && count > 0) {
      setData([])
      setCompleted(false)
      setTotalPages(Math.ceil(rowsToDownload / nbResultsPerPage))
      setCurrentPage(1)
    } else {
      setCurrentPage(undefined)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabled, count])

  /**
   * fetch data
   */
  useEffect(() => {
    if (!enabled || !currentPage || !totalPages || !exportCallback) {
      return
    }

    const exportRows = async () => {
      await wait(cooldownMs)

      /* fetch data */

      const results = await exportCallback({
        ...query,
        nbResultsPerPage,
        page: currentPage,
      })

      /* format for export */

      const chunks = (results?.data ?? [])
        ?.filter((row: any) => {
          return !data.find((item: any) => item.id === row.id)
        })
        ?.map((row: any) => {
          const entries = visibleColumns?.map(columnName => ([
            columns[columnName]?.label ?? columnName,
            exportFormatter?.(row, columnName),
          ])) ?? []

          return {
            id: row.id,
            ...Object.fromEntries(entries),
          }
        })

      const allData = [...data, ...chunks]
      setData(allData)

      if (currentPage < totalPages) {
        setCurrentPage((page) => (page ?? 1) + 1)
      } else {
        setCompleted(true)
        finishExport(allData)
      }
    }

    /* finish and download export */

    const finishExport = (data: any[]) => {
      const csv = json2csv(data, {
        emptyFieldValue: '',
        excelBOM: true,
        expandArrayObjects: true,
        excludeKeys: visibleColumns?.includes('id') ? undefined : ['id'],
        escapeHeaderNestedDots: false,
      })
      downloadBase64Data(
        Buffer.from(csv).toString('base64'),
        'export.csv',
      )
    }

    exportRows().catch((error) => {
      console.error(error)
      throw error
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage])

  return {
    completed,
    total: count,
    rowsToDownload,
    rowsDownloaded: Object.keys(data).length,
    progress,
  }
}
