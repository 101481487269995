import { Trans, useTranslation } from 'react-i18next'
import CustomerIcon from '@mui/icons-material/PermContactCalendarOutlined'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import AgentIcon from '@mui/icons-material/HeadsetMicOutlined'
import VisibilityIcon from '@mui/icons-material/Public'
import VisibilityPrivateIcon from '@mui/icons-material/PublicOff'

import {
  NoteScope,
  useDeleteNoteAttachmentAction,
  useShareNoteFromCustomerAction,
  useUpdateMoveNoteAction,
  useUploadNoteAttachmentAction,
  type FullMove,
} from '../../../../../modules/adminMoves'
import { useMoveState } from '../../../../../modules/moves'
import { Permission, usePermission } from '../../../../../modules/users'
import { mergeClassName } from '../../../../../utils/mergeClassName'
import Paper from '../../../../../components/Paper'
import MoveNote, {
  AuthorSource,
  MoveNotes,
} from '../../../../../components/MoveNote'
import { useErrorFormatter } from '../../../../../components/errors/useErrorFormatter'
import {
  errorNotification,
  successNotification,
} from '../../../../../components/ToastNotifications'
import Spinner from '../../../../../components/Spinner'

type NotesBoxProps = {
  move: FullMove
}

const NotesBox: React.FC<NotesBoxProps> = ({
  move,
}) => {
  const { t } = useTranslation()
  const { noteFromCurrentPartner, selectedQuote } = useMoveState().forMove(move)
  const { updateMoveNote } = useUpdateMoveNoteAction()
  const { uploadMoveNoteAttachment } = useUploadNoteAttachmentAction()
  const { deleteMoveNoteAttachment } = useDeleteNoteAttachmentAction()
  const { shareNoteFromCustomer, loading: sharingNoteFromCustomer } = useShareNoteFromCustomerAction()
  const formatError = useErrorFormatter()
  const canManageMoves = usePermission(Permission.canManageMoves)

  const customerNoteShared = !!move.notes?.shareNoteFromCustomer

  const handleEditNote = async (note: string | null | undefined, scope: NoteScope) => {
    try {
      await updateMoveNote(move.id, { note, scope })
      successNotification(t('pages.admin.move.notes.noteUpdated'))
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleUpload = async (file: File, scope: NoteScope) => {
    try {
      await uploadMoveNoteAttachment(move.id, { file, scope })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleDeleteFile = async (fileId: string, scope: NoteScope) => {
    try {
      await deleteMoveNoteAttachment(move.id, { fileId, scope })
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  const handleToggleShareNoteWithCustomer = async () => {
    try {
      const share = !move.notes?.shareNoteFromCustomer
      await shareNoteFromCustomer(move.id, share)
      if (share) {
        successNotification(t('pages.admin.move.notes.noteSharedWithPartner'))
      } else {
        successNotification(t('pages.admin.move.notes.noteNotSharedWithPartner'))
      }
    } catch (error) {
      errorNotification(formatError(error))
    }
  }

  return (
    <>

      <Paper>
        <h2 className="mb-2 flex items-center gap-x-2 font-body2 text-2xl font-semibold text-slate-700 ">
          <CustomerIcon />
          <div>
            { t('pages.admin.move.notes.customer') }
          </div>
        </h2>

        <div className="mb-4 ml-8 inline-flex items-center gap-x-1 text-sm text-slate-500">
          <VisibilityIcon className="!text-[18px]" />
          <div>
            <span className="underline">
              { t('pages.admin.move.notes.visibility.label') }
            </span>:
            { ' ' }
            <Trans i18nKey="pages.admin.move.notes.visibility.customer">
              <strong />
            </Trans>
            { ' ' }
            { customerNoteShared && (
              <Trans i18nKey="pages.admin.move.notes.visibility.customer_shared">
                <strong />
              </Trans>
            ) }
          </div>
        </div>

        <MoveNotes>
          <MoveNote
            note={move.notes?.fromCustomer}
            author={{
              source: AuthorSource.Customer,
              user: move.user,
            }}
            headerOptions={(
              <div className="flex items-center gap-x-2">
                { sharingNoteFromCustomer && <Spinner small /> }

                <div className="rounded-md border bg-white pl-2">
                  <FormControlLabel
                    control={<Checkbox checked={customerNoteShared} />}
                    disabled={sharingNoteFromCustomer}
                    label={(
                      <span className={mergeClassName(
                        'text-sm',
                        customerNoteShared && 'font-bold',
                      )}
                      >
                        { t('pages.admin.move.notes.shareWithPartner') }
                      </span>
                    )}
                    onClick={handleToggleShareNoteWithCustomer}
                  />
                </div>
              </div>
            )}
          />

          <MoveNote
            note={move.notes?.toCustomer}
            author={{
              source: AuthorSource.MovingWaldo,
            }}
            isAuthor
            onEdit={
              canManageMoves
                ? async note => { await handleEditNote(note, NoteScope.toCustomer) }
                : undefined
            }
          />
        </MoveNotes>
      </Paper>

      <Paper>
        <h2 className="mb-2 flex items-center gap-x-2 font-body2 text-2xl font-semibold text-slate-700 ">
          <CustomerIcon />
          <div>
            { t('pages.admin.move.notes.partner') }
          </div>
        </h2>

        <div className="mb-4 ml-8 inline-flex items-center gap-x-1 text-sm text-slate-500">
          <VisibilityIcon className="!text-[18px]" />
          <div>
            <span className="underline">
              { t('pages.admin.move.notes.visibility.label') }
            </span>:
            { ' ' }
            <Trans i18nKey="pages.admin.move.notes.visibility.partner">
              <strong />
            </Trans>
          </div>
        </div>

        <MoveNotes>
          { noteFromCurrentPartner?.note && selectedQuote?.companyBranch && (
            <MoveNote
              note={noteFromCurrentPartner.note}
              author={{
                source: AuthorSource.Partner,
                companyBranch: selectedQuote?.companyBranch,
              }}
            />
          ) }

          <MoveNote
            note={move.notes?.toPartner}
            author={{
              source: AuthorSource.MovingWaldo,
            }}
            isAuthor
            onEdit={
              canManageMoves
                ? async note => {
                  await handleEditNote(note, NoteScope.toPartner)
                }
                : undefined
            }
            onUpload={async file => { await handleUpload(file, NoteScope.toPartner) }}
            onDeleteFile={async file => { await handleDeleteFile(file, NoteScope.toPartner) }}
          />
        </MoveNotes>
      </Paper>

      <Paper>
        <h2 className="mb-2 flex items-center gap-x-2 font-body2 text-2xl font-semibold text-slate-700 ">
          <AgentIcon />
          <div>
            { t('pages.admin.move.notes.internal') }
          </div>
        </h2>

        <div className="mb-4 ml-8 inline-flex items-center gap-x-1 text-sm text-slate-500">
          <VisibilityPrivateIcon className="!text-[18px]" />
          <div>
            <span className="underline">
              { t('pages.admin.move.notes.visibility.label') }
            </span>:
            { ' ' }
            <Trans i18nKey="pages.admin.move.notes.visibility.internal">
              <strong />
            </Trans>
          </div>
        </div>

        <MoveNotes>
          <MoveNote
            note={move.notes?.internal}
            author={{
              source: AuthorSource.MovingWaldo,
            }}
            isAuthor
            onEdit={
              canManageMoves
                ? async note => { await handleEditNote(note, NoteScope.internal) }
                : undefined
            }
            onUpload={async file => { await handleUpload(file, NoteScope.internal) }}
            onDeleteFile={async file => { await handleDeleteFile(file, NoteScope.internal) }}
          />
        </MoveNotes>
      </Paper>
    </>
  )
}

export default NotesBox
